@use '../styles/colors';

/* mat element override styles */

/* source category edit form - bold text for Cat name */
source-category-form .sourceCategoryForm__row--categoryNameInput.mat-mdc-input-element {
    font-weight: 500;
}
/* source category edit form - name input when disabled */
source-category-form .sourceCategoryForm__row--categoryNameInput.mat-mdc-input-element:disabled {
    color: colors.$darker-gray;
}

.sourceCategoryForm__row--categoryName .mdc-text-field--disabled.mdc-text-field--filled {
    background: none;
}

/* source category edit form - colors for mat-chip */
source-category-form .sourceCategoryForm__row .mat-mdc-chip.mat-mdc-standard-chip {
    font-size: 0.75rem;
    background-color: colors.$light_accent;
    color: colors.$light_accent-contrast;
    min-height: 20px;
    opacity: 1;
}

/* source category edit form - confirmation buttons positioning */
.deleteCategoryDialog mat-dialog-actions.confirmDialog__actions {
    justify-content: flex-start;
}
