@use '@angular/material' as mat;
@use './colorsPalettes' as palettes;
@use './theme';
@use 'sass:map';

// Define and build project color variables

$color-config: mat.get-color-config(theme.$inntopia-light-theme);
$background-palette: map-get($color-config, background); /* material background color palette */
$foreground-palette: map-get($color-config, foreground); /* material foreground color palette */

/* color variables */
/* purples */
$light_primary: mat.get-color-from-palette(theme.$inntopia-light-primary-palette); /* ex: button color */
$light_primary-lt: mat.get-color-from-palette(theme.$inntopia-light-primary-palette,'lighter');
$light_primary-lt-contrast: mat.get-color-from-palette(theme.$inntopia-light-primary-palette, 'lighter-contrast');
$light_primary-def-contrast: mat.get-color-from-palette(theme.$inntopia-light-primary-palette, 'default-contrast');
$light_primary-100: mat.get-color-from-palette(theme.$inntopia-light-primary-palette,100); /* ex: light background color */

/* oranges */
$light_accent: mat.get-color-from-palette(theme.$inntopia-light-accent-palette); /* orange color #ed6e06 */
$light_accent-lt: mat.get-color-from-palette(theme.$inntopia-light-accent-palette,'lighter'); /* ex: footer bg color #fad4b4 */
$light_accent-dk: mat.get-color-from-palette(theme.$inntopia-light-accent-palette,'darker'); /* ex: footer text color #e03f02 */
$light_accent-contrast: mat.get-color-from-palette(theme.$inntopia-light-accent-palette,'500-contrast'); /* ex: sources chip text color #ffffff */

/* reds */
$light_warn: mat.get-color-from-palette(theme.$inntopia-light-warn-palette);
$light_warn-lt: mat.get-color-from-palette(theme.$inntopia-light-warn-palette,'lighter');

/* grays */
$black: mat.get-color-from-palette($foreground-palette, base); /* black 000000 */
$darker-gray: mat.get-color-from-palette($foreground-palette, text); /* very dark gray - rgba(0, 0, 0, 0.87) */
$dark-gray: mat.get-color-from-palette($foreground-palette, icon); /* dark gray rgba(0, 0, 0, 0.54) */
$gray: mat.get-color-from-palette($foreground-palette, disabled); /* gray rgba(0, 0, 0, 0.38) */
$light-gray: mat.get-color-from-palette($foreground-palette, divider); /* light gray - rgba(0, 0, 0, 0.12) */
$light-gray-bg: mat.get-color-from-palette($background-palette, background); /* very light gray bg color #fafafa */

/* Severity level colors */
$alert: #F24141;
$critical: #ff6600;
$warn: #F2B705;
$info: #3BBCD9;
