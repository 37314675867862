@use './colors';

/* styles for non-material components/elements */

body {
    margin: 0;
    height: 100vh;
    min-width: 1080px;
    background-color: colors.$light-gray;
}

.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > ng-component {
        flex-grow: 2;
    }
}

// All content styles. Added to top of each page.
.contentWrapper {
    margin: 1rem;
}

// Styles only applied to page info cards.
mat-card.card--infoCard {
    width: 49.5%;
    margin-bottom: 1rem;
}

// promo detail inspector tooltip
.packageComponents__componentList--mat-tooltip.mat-mdc-tooltip {
    margin-top: -1em;
}

// iFrame-specific styles
.usersTool--iframe {

    mat-form-field.userManagementForm__row--role {
        display: none;
    }

    .userManagementForm__row__button button {
        min-width: unset;
        padding: 0 10px;
        margin: 5px 0;
    }

    edit-user-form .userManagementForm__row--emailField {
        flex-basis: 26%;
        max-width: 26%;
    }

    .userManagementForm__row .permission__system {
        display: none;
    }
}

// Give margins related to sales id forms. This is mostly for search button next to the field.
.adminSearchCard__form {
    margin-bottom: 1rem;
}


// controlling size/position of phone field country dropdown options
intl-tel-input .iti__country-list {
    left: -70px;
    max-width: 390px;
    top: -6em;
    font-size: 0.85rem;
    height: 7rem;
}
