@use '../../styles/colors';

/* mat element override styles */
rate-filter-form .mat-select-disabled .mat-select-value {
    color: colors.$darker-gray;
}
/* rate filter form - mat-card/wrapper styles*/
rate-filter-form .mat-mdc-card.rateFilterFormWrapper {
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid colors.$light-gray;
    padding: 16px 16px 0 16px;
}
