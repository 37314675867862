@use './colors';

/* @use component override modules that contain mat element style overrides */
@use '../productHurdleTools/rateFilterTool/rateFilterForm.component.override';
@use '../searchOptions/searchOptions.component.override';
@use '../sources/sourceCategoryForm.component.override';

/* global styles for material components */

// Sets hover highlight on all Material Table
.mat-mdc-table tr.mat-mdc-row:hover {
    background-color: colors.$light-gray;
}

/* visibility toggle 'eye' icon - for example, password field */
.createNewUserForm .form--visibilityIcon {
    color: colors.$light_primary;
    font-size: 1em;
    cursor: pointer;
}

mat-form-field.resetPwForm--confirmPwField {
    margin: 1.5em 0;
}

/* international phone field for create new user */
intl-tel-input mat-form-field {
    width: 100%;
}

create-new-user-form {
    & .mat-mdc-form-field-hint-wrapper {
        padding: 0;
        line-height: 1;
    }

    & .mat-mdc-text-field-wrapper {
        overflow: visible;
    }

    & .mat-mdc-form-field-subscript-wrapper {
        height: 3rem;
    }
}

account-info-form .mat-mdc-text-field-wrapper {
    overflow: visible;
}
